import { NgClass, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

export enum ToolTipBackground {
  Grey,
  None,
}

export enum ToolTipIcon {
  Info,
  Warning,
}

@Component({
  standalone: true,
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrl: "./tooltip.component.scss",
  imports: [MatIconModule, MatTooltipModule, NgClass, NgIf],
})
export class TooltipComponent implements OnInit, OnChanges {
  @Input({ required: true }) public text!: string;
  @Input() public background: ToolTipBackground = ToolTipBackground.None;
  @Input() public icon: ToolTipIcon = ToolTipIcon.Info;

  protected readonly ToolTipIcon = ToolTipIcon;

  public iconClasses = "";
  public tooltipClasses = "";

  public ngOnInit(): void {
    this.setExtraClasses();
  }

  public ngOnChanges(): void {
    this.setExtraClasses();
  }

  private setExtraClasses(): void {
    this.iconClasses = this.getIconClasses();
    this.tooltipClasses = this.getTooltipClasses();
  }

  private getIconClasses(): string {
    const iconClassString =
      this.icon === ToolTipIcon.Warning
        ? "tooltip-icon--warning"
        : "tooltip-icon--info";
    return `tooltip-icon ${iconClassString}`;
  }

  private getTooltipClasses(): string {
    const backgroundClassString =
      this.background === ToolTipBackground.Grey ? "grey-background" : "";
    return `tooltip ${backgroundClassString}`;
  }
}
